<template>
	<div>
		<!-- <div class="py-3 my-3 mx-2">
            * calculation after cap<br>
            ** calculation before cap<br><br>
            SKU : {{ Sku }}<br>
            Pricing Code : {{ PricingCode }}<br>
            Pricing : {{ Pricing }}<br>
            Rounding : {{ Rounding }}<br>
            Subtotal : {{ formatCurrency(DetailPricing.sub_total) }}<br><br>
        </div> -->
        <vs-row>
			<vs-col :vs-w="12" vs-justify="justify" vs-align="justify">
				<span>
					* calculation after cap<br>
					** calculation before cap<br><br>
					SKU : {{ Sku }}<br>
					Pricing Code : {{ PricingCode }}<br>
					Pricing : {{ Pricing }}<br><br>
					Rounding : {{ Rounding }}<br>
					Subtotal : {{ formatCurrency(DetailPricing.sub_total) }}<br><br>
				</span>
				<vs-table stripe border :sst="true" :data="priceStructure">
					<template slot="thead">
						<vs-th sort-key="">No</vs-th>
						<vs-th sort-key="promotion_sequence">Promotion Sequence</vs-th>
						<vs-th sort-key="promotion_type">Promotion Type</vs-th>
						<vs-th sort-key="promotion_code">Promotion Code</vs-th>
						<vs-th sort-key="discount_type">Discount Type</vs-th>
						<vs-th sort-key="amount">Amount *</vs-th>
						<vs-th sort-key="base">Base</vs-th>
						<vs-th sort-key="qty">Qty</vs-th>
						<vs-th sort-key="main">Main **</vs-th>
						<vs-th sort-key="add">Add **</vs-th>
						<vs-th sort-key="cap">Cap</vs-th>
						<vs-th sort-key="capped">Capped</vs-th>
						<vs-th sort-key="from">From</vs-th>
						<vs-th sort-key="to">To</vs-th>
					</template>
					<template slot-scope="{ data }">
						<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
							<vs-td v-if="tr.type=='calculation'">{{ tr.row }}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_sequence }}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_type }}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.promotion_code }}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.discount_string }}</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">{{ formatCurrency(tr.calculation.amount.toFixed(2)) }}</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right"><small>({{ tr.calculation.base_string }})</small><br>{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.base_amount.toFixed(2)) : tr.calculation.base_amount}}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.qty }}</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">
							  <small>(rate)</small><br>
							  {{ 
								(tr.calculation.discount_type==2?"IDR":"") +
								formatCurrency(tr.calculation.main_rate.toFixed(2)) +
								(tr.calculation.discount_type==1?"%":"")}}<br><br>
							  <small>(amount)</small><br>
							  {{ formatCurrency(tr.calculation.main.toFixed(2)) }}
							</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">
							  <small>(rate)</small><br>
							  {{ 
								(tr.calculation.discount_type==2?"IDR":"") +
								formatCurrency(tr.calculation.add_rate.toFixed(2)) +
								(tr.calculation.discount_type==1?"%":"")}}<br><br>
							  <small>(amount)</small><br>
							  {{ formatCurrency(tr.calculation.add.toFixed(2)) }}
							</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">{{ formatCurrency(tr.calculation.cap.toFixed(2)) }}</vs-td>
							<vs-td v-if="tr.type=='calculation'">{{ tr.calculation.capped }}</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.from.toFixed(2)) : tr.calculation.from }}</vs-td>
							<vs-td v-if="tr.type=='calculation'" align="right">{{ (tr.calculation.base_string == "value") ? formatCurrency(tr.calculation.to.toFixed(2)) : tr.calculation.to }}</vs-td>
							<!-- baris subtotal -->
							<vs-td v-if="tr.type!='calculation'" colspan="4"></vs-td>
							<vs-td v-if="tr.type!='calculation'"><b>{{ tr.type }}</b></vs-td>
							<vs-td v-if="tr.type!='calculation'" align="right"><b>{{ calculated ? formatCurrency(tr.calculation.order_line_after_discount) : formatCurrency(tr.calculation.order_line) }}</b></vs-td>
							<vs-td v-if="tr.type!='calculation'" colspan="9"></vs-td>
						</vs-tr>
					</template>
				</vs-table>
			</vs-col>
		</vs-row>
    </div>
</template>

<script>
import moment from 'moment'
export default {
	props: ["selectedDiscount","Sku","PricingCode","Pricing","Subtotal","Rounding", "DetailPricing", "calculated"],
	components: {
		// 
	},
	mounted() {
		// 
		console.log("selectedDiscount", this.selectedDiscount)
		console.log("calculated", this.calculated)
		this.setDataDetail();
	},
	data() {
		return {
			table: {
				data: [],
				length: 10,
				page: 1,
				search: "",
				order: "id",
				sort: "asc",
				total: 0,
				totalPage: 0,
				totalSearch: 0,
				limits: [10, 25, 50, 100],
				start: 1,
				end: 0,
			},
            priceStructure: [],
		};
	},
	methods: {
		dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment(String(date)).format('DD/MM/YYYY HH:mm')
            }
        },
		setDataDetail() {
			this.priceStructure = []
			let subtotalNo = 1
			let subtotalString = ""
			let DetailDataDiscount = this.selectedDiscount.promo_calculation;

			// console.log("DetailDataDiscount", DetailDataDiscount)
			// console.log("this.DetailPricing", this.DetailPricing)
			// console.log("this.DetailPricing.sub_total", this.DetailPricing.sub_total)

			DetailDataDiscount.forEach((calculation, index) => {
				this.table.data.push(calculation);
				this.priceStructure.push({ row:index+1, type: 'calculation', calculation });
				
				if (index + 1 < DetailDataDiscount.length && calculation.promotion_sequence != DetailDataDiscount[index + 1].promotion_sequence) {
					subtotalString = "subtotal "+subtotalNo
					this.priceStructure.push({ type: subtotalString,  calculation });
					subtotalNo++
				}
	
				if (index == DetailDataDiscount.length-1) {
					subtotalString = "subtotal "+subtotalNo
					this.priceStructure.push({ type: subtotalString,  calculation});
					subtotalNo++
				}
			});

			if (!this.calculated){
				// rounding & nett
				this.priceStructure.push({ type: "rounding",  calculation:{
					"order_line": this.DetailPricing.rounding.toFixed(2)
				}});
				// this.priceStructure.push({ type: "nett",  calculation:{
				//   "order_line_after_discount": this.DetailPricing.nett
				// }});
		
				// grossup
				this.priceStructure.push({ type: "grossup",  calculation:{
				  "order_line": this.DetailPricing.gross_up.toFixed(2)
				}});
		
				// dpp
				this.priceStructure.push({ type: "dpp",  calculation:{
				  "order_line": this.DetailPricing.dpp.toFixed(2)
				}});
		
				// tax
				this.priceStructure.push({ type: "tax",  calculation:{
				  "order_line": this.DetailPricing.tax
				}});
		
				// grand_total
				this.priceStructure.push({ type: "grand total",  calculation:{
				  "order_line": this.DetailPricing.grand_total
				}});
			} else {
				// rounding & nett
				this.priceStructure.push({ type: "rounding",  calculation:{
					"order_line_after_discount": this.DetailPricing.rounding.toFixed(2)
				}});
				// this.priceStructure.push({ type: "nett",  calculation:{
				//   "order_line_after_discount": this.DetailPricing.nett
				// }});
		
				// grossup
				this.priceStructure.push({ type: "grossup",  calculation:{
				  "order_line_after_discount": this.DetailPricing.gross_up.toFixed(2)
				}});
		
				// dpp
				this.priceStructure.push({ type: "dpp",  calculation:{
				  "order_line_after_discount": this.DetailPricing.dpp.toFixed(2)
				}});
		
				// tax
				this.priceStructure.push({ type: "tax",  calculation:{
				  "order_line_after_discount": this.DetailPricing.tax
				}});
		
				// grand_total
				this.priceStructure.push({ type: "grand total",  calculation:{
				  "order_line_after_discount": this.DetailPricing.grand_total
				}});
			}
		  
		  // console.log("this.selectedDiscount", this.selectedDiscount)
		  // for (let a = 0; a < 20; a++) {
		  // }
		  console.log("this.table.data", this.table.data)
		  console.log("this.priceStructure", this.priceStructure)
		},
		formatCurrency(value) {
          if (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        },
	},
  	watch: {
        watchedProperties: function() {
			console.log("selectedDiscount", this.selectedDiscount)
			this.setDataDetail();
        }
    },
	computed: {
		watchedProperties() {
            return this.selectedDiscount;
        },
	},
};
</script>

 <style scoped>
 
</style>